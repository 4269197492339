<template>
  <footer class="pull-right mt-1">
    <a href="#" @click="show" class="flex items-center">
      <!-- <img src="@/assets/images/openai.svg" width="18" class="mr-1" /> -->
      {{ $t("property.openAi.description") }}
    </a>
    <form-dialog
      :autofocus="false"
      :visible="visible"
      :title="$t('property.openAi.title', { title: fieldName })"
      append-to-body
      @close="visible = false"
      :show-footer="false"
    >
      <iframe ref="iframe" width="100%" :height="height" frameborder="0" :src="iFrameSrc" v-if="iFrameSrc"></iframe>
    </form-dialog>
  </footer>
</template>

<script>
import { COMPONENT_EXTENSIONS } from "@/config/extensions"

export default {
  props: {
    extensionName: {
      type: String,
      required: true,
    },
    resourceId: {
      type: Number,
      required: true,
    },
    resourceType: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: "500",
    },
  },
  data() {
    return {
      visible: false,
      handler: null,
      iFrameSrc: globalThis.propertyFrame,
    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        const that = this
        this.handler = e => {
          if (e.origin !== that.allowedOrigin) return
          console.log("Message received", e)
          this.$emit("submit", e.data)
          this.visible = false
        }
        globalThis.addEventListener("message", this.handler)
      } else if (this.handler) {
        globalThis.removeEventListener("message", this.handler)
      }
    },
  },
  methods: {
    show() {
      this.visible = true
      this.initialize()
    },
    async initialize() {
      const { data } = await this.$axios.get(
        `${this.viewExtension.url}?resource_id=${this.resourceId}&resource_type=${this.resourceType}`
      )
      this.iFrameSrc =
        this.devUrl ||
        [data.url, `target=generateText&fieldName=${this.field}`]
          .filter(Boolean)
          .join(data.url.includes("?") ? "&" : "?")
    },
  },
  computed: {
    devUrl() {
      return this.$route.query.devUrl
    },
    viewExtension() {
      return COMPONENT_EXTENSIONS["propertyWriter"][this.extensionName]
    },
    allowedOrigin() {
      return this.iFrameSrc ? new URL(this.iFrameSrc).origin : undefined
    },
    baseUrl() {
      return EXTENSIONS["areabutler"]["view"]
    },
  },
}
</script>
